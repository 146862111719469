import React, {type FC} from 'react';
import './icons.scss'

const VIEW_BOX = '0 0 24 24'
const ICON_SIZE = 22

type Props = React.SVGProps<SVGSVGElement>

interface BaseProps {
  props?: Props
  children: JSX.Element | JSX.Element[]
}

const Base: FC<BaseProps> = ({ props, children }) => <svg
    viewBox={VIEW_BOX}
    strokeWidth={1.7}
    stroke={'var(--text-primary)'}
    width={ICON_SIZE}
    height={ICON_SIZE}
    fill={'none'}
    strokeLinecap={'round'}
    // Apply props after defaults
    {...props}
  >
    {children}
  </svg>

/** An X symbol */
const Cross = () => <Base>
  <line x1='18' y1='6' x2='6' y2='18'></line>
  <line x1='6' y1='6' x2='18' y2='18'></line>
</Base>

/** A funnel (filter) symbol */
const Funnel: FC<Props> = (props) => <Base props={props}>
    <polygon points="18 6 6 6 10.6 11.46 10.6 17 13.4 18 13.4 11.46 18 6" />
  </Base>

/** A menu (three lines) symbol */
const Menu = () => <Base>
  <line x1='6' y1='6' x2='18' y2='6' />
  <line x1='6' y1='12' x2='18' y2='12' />
  <line x1='6' y1='18' x2='18' y2='18' />
</Base>

const Spinner = () => <Base props={{ viewBox: '0 0 24 24', className: 'spinner' }}>
  <circle cx='12' cy='12' r='6' stroke='var(--text-secondary)'></circle>
  <path stroke='var(--text-primary)' d='M 6,12 A 6,6 0,0,1 12,6' />
</Base>

/** Green checkmark */
const Checkmark = ({ stroke }: { stroke?: string }) => <Base props={{ stroke: stroke ?? 'green' }}>
  <circle cx='12' cy='12' r='6'></circle>
  <line x1='9' x2='12' y1='11' y2='14.5'></line>
  <line x1='12' x2='15' y1='14.5' y2='10'></line>
</Base>

const LeftArrow = () => <Base>
  <line x1='15' x2='9' y1='6' y2='12'></line>
  <line x1='15' x2='9' y1='18' y2='12'></line>
</Base>

const RightArrow = () => <Base>
  <line x1='15' x2='9' y1='12' y2='6'></line>
  <line x1='15' x2='9' y1='12' y2='18'></line>
</Base>

const UpArrow: FC<Props> = (props) => <Base props={props}>
  <line x1='6' y1='15' x2='12' y2='9'></line>
  <line x1='18' y1='15' x2='12' y2='9'></line>
</Base>

const DownArrow: FC<Props> = (props) => <Base props={props}>
  <line x1='6' y1='9' x2='12' y2='15'></line>
  <line x1='18' y1='9' x2='12' y2='15'></line>
</Base>

export {
  Cross,
  Funnel,
  Menu,
  Spinner,
  Checkmark,
  LeftArrow,
  RightArrow,
  UpArrow,
  DownArrow,
  ICON_SIZE
}