import { type AuctionStatus, type BaseAuction } from '../../../classes/Auction'
import React, { type CSSProperties, Fragment, useState } from 'react'
import { Checkmark, UpArrow } from '../../../components/Icons/Icons'
import { AuctionListElement } from './AuctionListElement'
import { groupBy } from '../../../Util'

const getSetOf = <T, U extends keyof T>(list: T[], key: U) => {
  const set = new Set<T[U]>()
  for (const value of list) {
    set.add(value[key])
  }
  return set
}

// Mark by importance. Yellow checkmark if at least one has update, green only if all are green.
export const getGroupStatusSymbol = (statuses: AuctionStatus[]) => {
  if (statuses.includes('UPDATE_AVAILABLE'))
    return <Checkmark stroke={'rgb(204,204,57)'} />

  if (statuses.every(s => s === 'SUBMITTED'))
    return <Checkmark />
}

// Get style for auctionName that holds the group of statuses given here
export const getGroupStyle = (statuses: AuctionStatus[]) => {
  const style: CSSProperties = {}

  // If status is 'None', we grey out the auction name
  if (statuses.every(s => s === 'NONE')) {
    style.color = 'var(--text-secondary)'
  }

  // If we
  if (statuses.includes('PENDING') || statuses.includes('UPDATE_AVAILABLE')) {
    style.fontStyle = 'italic'
  }

  // Add bold
  if (statuses.includes('PENDING')) {
    style.fontWeight = 'bold'
  }
  return style
}

// Sort map entries
const groupSorting = (g1: [string, BaseAuction[]], g2: [string, BaseAuction[]]) => g1[0].localeCompare(g2[0])

interface SideBarElementProps<T extends BaseAuction> {
  title: string
  auctions: T[]
  auction: T | undefined
  setAuction: React.Dispatch<React.SetStateAction<BaseAuction | undefined>>
  sorting: (a: T, b: T) => number
}


// This is how we mark the different states in the UI.
// - None -> Greyed out
// - Pending -> Italic
// - Submitted -> Green checkmark
// - UpdateAvailable -> Yellow checkmark
export const SideBarTree = <T extends BaseAuction>(
  { title, auctions, auction, setAuction, sorting }: SideBarElementProps<T>,
) => {

  const [opened, setOpened] = useState(new Set())
  const grouped = groupBy(auctions, a => a.name)

  const onClick = (auction: BaseAuction) => {
    setAuction(prev => prev?.key() !== auction.key() ? auction : undefined)
  }


  return (<Fragment>
    <div className='title'>{title}</div>
    <ul className='auctions-bar'>
      {[...grouped.entries()]
        .sort(groupSorting)
        .map(([auctionName, portfolios]) => {
          const statuses = [...getSetOf(portfolios, 'status')]
          const statusSymbol = getGroupStatusSymbol(statuses)
          const isOpen = opened.has(auctionName)

          return <Fragment key={auctionName}>
            <li
              className={'auction-status'}
              onClick={() => {
                setOpened(prev => {
                  if (!prev.has(auctionName)) return new Set([...prev, auctionName])
                  else {
                    // Test if the auction picked is in this group. We unpick it then
                    const keys = portfolios.map(p => p.key())
                    setAuction(picked => picked && keys.includes(picked.key()) ? undefined : picked)
                    return new Set([...prev].filter(p => p !== auctionName))
                  }
                })
              }}
              style={getGroupStyle(statuses)}
            >
              <div className='auction-status-inner' style={{ backgroundColor: undefined}}>
                <div className='auction-status-name'>
                  {auctionName}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {<UpArrow className={`button-icon ${isOpen ? 'opened' : 'closed'}`}/> }
                  {statusSymbol}
                </div>
              </div>
            </li>
            {isOpen && <>
              {portfolios.sort(sorting).map(portfolio => (
                <AuctionListElement
                  key={portfolio.key()}
                  auction={auction}
                  element={portfolio}
                  onClick={onClick}
                  offset={true}
                />
              ))}
            </>}
          </Fragment>
        })}
    </ul>
  </Fragment>)
}