import { type Moment } from 'moment'
import { type Product } from '../../../classes/Product'
import React, { type FC, Fragment, useEffect, useState } from 'react'
import Range from '../../../components/Range/Range'
import { type Portfolio } from '../../../classes/Portfolio'
import { type HalfAuction } from '../../../classes/Interconnector'
import OrderBookCell, { type TakeTrade } from '../OrderBookCell/OrderBookCell'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { TRADE_HANDLE } from '../../../stores/ConfigStore'
import { isValidTradeHandle } from '../../../components/Executor/ExecutorHelpers'
import { toast } from 'react-toastify'
import { placeSpreadOrder } from '../../../actions/Interconnectors'
import { useSubscribe } from '../../../hooks/useSubscribe'
import { Spinner } from '../../../components/Icons/Icons'
import { Multiplier } from '../../../Util'
import { getLossFunction } from '../calculateMidPointNomination'
import type { SpreadOrder } from '../../../classes/SpreadOrder'
import './spread-row.scss'
import type { SpreadTrade } from '../../../classes/SpreadTrade'
import { getPortfolioDivisor, profitSign } from '../calculationHelpers'
import { Conditional } from '../Conditional'
import type { Currency } from '../../../classes/Currency'

interface SpreadRowProps {
  transmissionRight: number,
  marginalPrice: number,
  currentMidInterconnectorNomination: number,
  contractGroup: ContractGroup,
  halfAuction: HalfAuction,
  exportPortfolio: Portfolio,
  importPortfolio: Portfolio
  trades: SpreadTrade[]
  gbpExchangeRate: number
}

export interface ContractGroup {
  deliveryStart: Moment
  deliveryEnd: Moment
  exports: Product[]
  imports: Product[]
}

export type AuctionSide = 'export' | 'import'

// Returns quantity divided by the delivery period length (that is, for half hours, we half qty)
const divided = (portfolios: Portfolio[]) => {
  const divisors = new Map<string, number>()
  for (const portfolio of portfolios) {
    divisors.set(portfolio.eic, getPortfolioDivisor(portfolio))
  }

  return (trade: SpreadTrade) => {
    return trade.quantity / divisors.get(trade.area)!
  }
}

// Calculates the current profit/loss of an IDC hour
const calculateProfit = (allocation: number, marginalPrice: number, exchangeRate: number, trades: SpreadTrade[], divided: (trade: SpreadTrade) => number) => {
  const sum = (acc: number, trade: SpreadTrade) => acc + trade.unitPrice * divided(trade) * profitSign(trade)

  const transmissionCost = allocation * marginalPrice * -1
  const converted = trades.filter(t => t.currency === 'GBP').reduce(sum, 0) * exchangeRate
  const tradeProfit = trades.filter(t => t.currency === 'EUR').reduce(sum, 0)
  return (tradeProfit + converted + transmissionCost) / (Multiplier.MW * Multiplier.EUR)
}

interface OrderBooksProps {
  grouped: Array<{
    key: AuctionSide
    contracts: Product[],
    lossFunction: (n: number) => number
  }>
  importPortfolio: Portfolio,
  exportPortfolio: Portfolio,
  trades: SpreadTrade[]
  // The position we want to take - order books are going to subtract their current pos
  fullPosition: number,
  tradingIsEnabled: boolean
  indexMap: Record<string, number>
  setCost: React.Dispatch<React.SetStateAction<Array<TakeTrade | undefined>>>
  currencyConverter: (currency: Currency, n: TakeTrade | undefined) => TakeTrade | undefined
}

// Render all [OrderBookCell]s
// For a portfolio:
// - if there are no contracts, do not render anything
// - if there is one, we render on in a 'single' div
// - If there are two for a portfolio, we render both in a 'double' div
const OrderBooks: FC<OrderBooksProps> = (props) => {
  const { grouped, importPortfolio, exportPortfolio } = props
  const { fullPosition, tradingIsEnabled, indexMap, setCost } = props
  const { trades, currencyConverter } = props
  
  return <>
    {grouped.map((group, idx) => {
      const { key, contracts, lossFunction } = group
      const portfolio = key === 'export' ? exportPortfolio : importPortfolio
      const multiplier = key === 'export' ? 1 : -1

      if (contracts.length === 0) {
        return <div key={`no-contract-${idx}`}>{'No contracts'}</div>
      }

      if (contracts.length === 1) {
        const [contract] = contracts
        const position = multiplier * lossFunction(fullPosition)
        const costIndex = indexMap[indexKey(portfolio, contract)]
        const filteredTrades = trades.filter(({ contractId, area }) => contractId === contract.contractId && area === portfolio.eic)

        return <div className='single' key={`${key}${contract.contractId}`}>
          <OrderBookCell
            contract={contract} portfolio={portfolio}
            trades={filteredTrades}
            fullPosition={position}
            enabled={tradingIsEnabled}
            setTakePositionCost={n => {
              setCost(prev => [
                ...prev.slice(0, costIndex),
                currencyConverter(portfolio.currency, n),
                ...prev.slice(costIndex + 1)
              ])
            }}
          /></div>
      }

      // There are two contracts
      return <div
        className='double'
        key={`${key}${contracts.map(c => c.contractId).join()}`}
      >
        {contracts
          .sort((a, b) => a.deliveryStart.localeCompare(b.deliveryStart))
          .map(c => {
            const position = multiplier * lossFunction(fullPosition)
            const costIndex = indexMap[indexKey(portfolio, c)]
            const filteredTrades = trades.filter(({ contractId, area }) => contractId === c.contractId && area === portfolio.eic)

            return <OrderBookCell
              key={c.contractId}
              contract={c} portfolio={portfolio}
              trades={filteredTrades}
              fullPosition={position}
              enabled={tradingIsEnabled}
              setTakePositionCost={n => {
                setCost(prev => [
                  ...prev.slice(0, costIndex),
                  currencyConverter(portfolio.currency, n),
                  ...prev.slice(costIndex + 1)
                ])
              }}
            />
          })}
      </div>
    })}
  </>
}

const indexKey = (portfolio: Portfolio, contract: Product) => `${portfolio.eic}${contract.contractId}`

// Create a map that points from a string key to an index number
const createIndexMap = (combinations: Array<{ portfolio: Portfolio, contract: Product, key: string }>) => {
  const indexMap: Record<string, number> = {}
  for (const [idx, { portfolio, contract }] of combinations.entries()) {
    indexMap[indexKey(portfolio, contract)] = idx
  }
  return indexMap
}

export const SpreadRow: FC<SpreadRowProps> = (
  {
    transmissionRight,
    marginalPrice,
    currentMidInterconnectorNomination,
    contractGroup,
    exportPortfolio,
    importPortfolio,
    halfAuction,
    trades,
    gbpExchangeRate
  },
) => {
  const { deliveryStart, deliveryEnd, imports, exports } = contractGroup

  const [filteredTrades, setFilteredTrades] = useState<SpreadTrade[]>([])
  const [tradePosition, setTradePosition] = useState(Math.min(transmissionRight - currentMidInterconnectorNomination, 1000))
  const [costs, setCosts] = useState([...Array(exports.length + imports.length)].fill(undefined) as Array<TakeTrade | undefined>)

  // Hold an ID for spread order currently out
  const [orderOutId, setOrderOutId] = useState<string | undefined>(undefined)
  useSubscribe('/topic/spread', (elem: { uuid: string }) => {
    setOrderOutId(prev => prev === elem.uuid ? undefined : prev)
  })

  // So we can see who trades
  const tradeHandle = useLocalStorage(TRADE_HANDLE)

  const contractIds = [...exports, ...imports].map(p => p.contractId)

  useEffect(() => {
    setFilteredTrades(trades.filter(t => contractIds.includes(t.contractId)))
  }, [importPortfolio, exportPortfolio, trades])

  // If the current mid-nomination changes (increases), we check that we are not over the range picker max (this happens when we have just traded).
  useEffect(() => {
    setTradePosition(prev =>
      transmissionRight - currentMidInterconnectorNomination < prev ? transmissionRight - currentMidInterconnectorNomination : prev
    )
  }, [currentMidInterconnectorNomination])

  // Positive affirmations...
  const quantityDivider = divided([importPortfolio, exportPortfolio])
  const profit = calculateProfit(transmissionRight, marginalPrice, gbpExchangeRate, filteredTrades, quantityDivider)

  // Group the contracts in export and import contracts, and combine with loss functions 
  const grouped: Array<{ key: AuctionSide, contracts: Product[], lossFunction: (n: number) => number }> = [
    { key: 'export', contracts: exports, lossFunction: getLossFunction(halfAuction, 'export') },
    { key: 'import', contracts: imports, lossFunction: getLossFunction(halfAuction, 'import') },
  ]
  
  const allContracts = [
    ...exports.map(c => ({ portfolio: exportPortfolio, contract: c, key: 'export' })),
    ...imports.map(c => ({ portfolio: importPortfolio, contract: c, key: 'import' }))
  ]

  // Create index map, where we assign each portfolio/product combination an index
  // This way they can update their respective place in the 'costs' state array
  const indexMap: Record<string, number> = createIndexMap(allContracts)

  // Try trading positions!
  const tradePositions = () => {
    // Validate that we have a trade handler
    if (!tradeHandle) {
      toast.error('No trade handle set')
      return
    }

    // Validate that trade handle is valid
    if (!isValidTradeHandle(tradeHandle)) {
      toast.error('Trade handle not valid')
      return
    }

    // Validate that we do not have any undefined TakeTrades
    if (costs.some(t => !t)) {
      toast.error('Missing price information')
      return
    }

    const placing: SpreadOrder = {
      tag: halfAuction.tag,
      tradeHandle,
      orders: costs.map((takeTrade, idx) => {
        const { portfolio, contract, key } = allContracts[idx]

        return {
          portfolioId: portfolio.eic,
          exchange: portfolio.exchange,
          contractId: contract.contractId,
          pricePoint: takeTrade!.pricePoint,
          volume: Math.abs(takeTrade!.volume),
          direction: key === 'import' ? 'SELL' : 'BUY',
          assumedCost: takeTrade!.cost,
        }
      }),
    }

    // Place spread order and set order id for spinner
    placeSpreadOrder(placing)
      .then(str => setOrderOutId(str))
      .catch(() => toast.error('Failed placing orders'))
  }

  const deliveryPeriod = `${deliveryStart.format('HH')}-${deliveryEnd.format('HH')}`

  // Our current mid-interconnector nomination + the amount the trader wants to trade
  const midInterconnectorFullPosition = currentMidInterconnectorNomination + tradePosition

  // Total cost is only valid if we have numbers for all contracts, so make sure this is NaN in any other case
  const totalCostOfPosition = costs.reduce((acc, value) => !value ? NaN : acc + value.cost, 0)

  // Cost per MW, can be positive or negative number.
  const costPerMW = totalCostOfPosition / tradePosition * Multiplier.MW
  const stringSign = costPerMW !== 0 ? Math.sign(costPerMW) === 1 ? '+' : '-' : ''

  const tradingIsEnabled = currentMidInterconnectorNomination < transmissionRight

  // Check if the profit per MW is higher than our marginal price
  const isProfitable = costPerMW > (marginalPrice / Multiplier.EUR)

  // Take a currency and a take trade. If the currency is GBP, multiply the cost by the exchange rate
  const currencyConverter = (currency: Currency, takeTrade: TakeTrade | undefined) =>
    takeTrade ?
      { ...takeTrade, cost: takeTrade.cost * (currency === 'EUR' ? 1 : gbpExchangeRate) }
      : undefined

  return <Fragment>
    <div className='single'>{deliveryPeriod}</div>
    <div className='double idc-base-info'>
      <div>
        <div className='idc-info-header' title={'Transmission right'}>{'TR:'}</div>
        <div>{transmissionRight / 1000}</div>
      </div>
      <div>
        <div className='idc-info-header' title={'Marginal price'}>{'MP:'}</div>
        <div>{marginalPrice / 100}</div>
      </div>
    </div>
    <div className='single'>
      <div
        title={'Mid-interconnector nomination'}
      >{`Mid: ${currentMidInterconnectorNomination / Multiplier.MW}`}</div>
      <Conditional value={tradingIsEnabled}>
        <div className='potential-value'>{`(${(midInterconnectorFullPosition) / Multiplier.MW})`}</div>
      </Conditional>
    </div>
    <div className='single'
         title={`${totalCostOfPosition.toFixed(2)} / ${tradePosition / Multiplier.MW} MW`}
    >
      <div>{`P/L: ${profit.toFixed(2)}`}</div>
      <Conditional value={tradingIsEnabled && !isNaN(costPerMW)}>
        <div className='potential-value'>
          <span>{'('}</span>
          <span
            style={{ color: isProfitable ? 'green' : 'red' }}>{`${stringSign}${Math.abs(costPerMW).toFixed(2)}/MW`}</span>
          <span>{')'}</span>
        </div>
      </Conditional>
    </div>
    <OrderBooks
      { ...{ grouped, importPortfolio, exportPortfolio } }
      trades={filteredTrades}
      fullPosition={midInterconnectorFullPosition}
      tradingIsEnabled={tradingIsEnabled}
      indexMap={indexMap}
      setCost={setCosts}
      currencyConverter={currencyConverter}
    />
    <div className='single'>
      <Conditional value={tradingIsEnabled}>
        <div className='text-field'>{`${tradePosition / Multiplier.MW} MW`}</div>
        <Range
          width={'100px'}
          max={transmissionRight - currentMidInterconnectorNomination}
          value={tradePosition}
          onChange={e => setTradePosition(Number(e.target.value))}
        />
      </Conditional>
    </div>
    <div className='single'>
      <Conditional value={tradingIsEnabled && !isNaN(totalCostOfPosition)}>
        <div className={'button ' + (orderOutId ? '' : 'clickable')} onClick={() => orderOutId ? {} : tradePositions()}>
          {orderOutId ? <div className='icon-holder'><Spinner /></div> : <div>Trade</div>}
        </div>
      </Conditional>
    </div>
  </Fragment>
}