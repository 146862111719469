import { useEffect, useRef, useState } from 'react'
import './settings.scss'
import { useStores } from '../../use-stores'
import { observer } from 'mobx-react'
import { isNumberField, NumberFieldType } from '../../classes/StrategySchema'
import { upperCaseFirstLetter } from '../Executor/Executor'
import { getMultiplier, OFFSET } from '../../Util'
import TimingEntry from './TimingEntry'
import useTimings from './useTimings'
import TimingForm from './TimingForm'
import { toast } from 'react-toastify'
import { fetchSpotPrices } from '../../actions/SpotPrices'

const Settings = observer(() => {
  const ref = useRef<any>() // reference for mouse events
  const { uiStore, configStore, executorStore, timingStore } = useStores()
  const { showTrades, showSpotPrices, showAlgorithms, useColoredAuctionCells } = uiStore
  const { getAllNumberFields, getAllOffsetFields } = executorStore
  const { updateSettingsField } = configStore
  const className = uiStore.settingsVisible
    ? { className: 'settings visible' }
    : { className: 'settings' }
  const {
    sync: syncTimings,
    remove: removeTiming,
    add: addTiming,
  } = useTimings()
  const [timingFormVisible, setTimingFormVisible] = useState(false)

  // Close settings on escape or mouse click
  window.addEventListener('keydown', (key) => {
    if (key.code === 'Escape' && uiStore.settingsVisible)
      uiStore.toggleSettings()
  }) // Close settings

  const updateFieldDefault = (
    name: string,
    value: string,
    quantifier: string,
  ) => {
    // If value length is 0, it means the user has deleted the number
    if (value.length === 0) {
      updateSettingsField(name, undefined)
    } else {
      updateSettingsField(
        name,
        Number(value) * getMultiplier(quantifier as NumberFieldType),
      )
    }
  }

  // Sync timings every 10 seconds
  useEffect(() => {
    syncTimings()
    const interval = setInterval(() => {
      syncTimings()
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div {...className} ref={ref}>
      <div className='settings-form'>
        <div className='settings-header'>
          <h4>General settings</h4>
        </div>
        <div className='label-input'>
          <label>Visible hours</label>
          <input
            type='number'
            min='10'
            value={uiStore.hoursAhead}
            onChange={(e) => {
              uiStore.setHoursAhead(Number(e.target.value))
            }}
          />
        </div>
        <div className='label-input'>
          <label>Show trades</label>
          <input
            checked={showTrades}
            onClick={() => {
              uiStore.setShowTrades(!showTrades)
            }}
            type='checkbox'
          />
        </div>
        <div className='label-input'>
          <label>Show spot prices</label>
          <input
            checked={showSpotPrices}
            onClick={() => {
              uiStore.setShowSpotPrices(!showSpotPrices)
            }}
            type='checkbox'
          />
        </div>
        <div className='label-input'>
          <label>Show algorithms</label>
          <input
            checked={showAlgorithms}
            onClick={() => {
              uiStore.setShowAlgorithms(!showAlgorithms)
            }}
            type='checkbox'
          />
        </div>
        <div className='label-input'>
          <label>Get spot prices</label>
          <button
            onClick={() => {
              fetchSpotPrices().catch(() => toast.error('Could not fetch.'))
            }}
          >
            {'Fetch'}
          </button>
        </div>
        <div className='theme-picker'>
          <div className='theme'>
            Light
            <div
              onClick={() => {
                uiStore.setTheme('light')
              }}
              className='color light'
            />
          </div>
          <div className='theme'>
            Dark
            <div
              onClick={() => {
                uiStore.setTheme('dark')
              }}
              className='color dark'
            />
          </div>
        </div>
      </div>
      <div className='settings-form'>
        <div className='settings-header'>
          <h4>Auction settings</h4>
        </div>
        <div className='label-input'>
          <label>Use colored cells</label>
          <input
            checked={useColoredAuctionCells}
            onClick={() => {
              uiStore.setUseColoredAuctionCells(!useColoredAuctionCells)
            }}
            type='checkbox'
          />
        </div>
      </div>
      <div className='settings-form'>
        <div className='settings-header'>
          <h4>Strategy settings</h4>
        </div>
        {getAllNumberFields.map((field) => {
          if (isNumberField(field)) {
            const existingValue = configStore.getSettingsValue(field.name)
            return (
              <div key={field.name} className='label-input'>
                <label>{upperCaseFirstLetter(field.name)}</label>
                <span className='mw'>
                  <input
                    type='number'
                    value={
                      existingValue
                        ? existingValue / getMultiplier(field.type)
                        : undefined
                    }
                    onChange={(e) => {
                      updateFieldDefault(field.name, e.target.value, field.type)
                    }}
                  />
                </span>
              </div>
            )
          } else return <div key={field.name} />
        })}
        {/* Get all offset settings */}
        {getAllOffsetFields.map((field) => {
          const existingValue = configStore.getSettingsValue(
            field.name + OFFSET,
          )
          return (
            <div key={field.name} className='label-input'>
              <label>{upperCaseFirstLetter(field.name) + ' offset'}</label>
              <span className='eur'>
                <input
                  type='number'
                  value={
                    existingValue
                      ? existingValue / getMultiplier(NumberFieldType.EUR)
                      : undefined
                  }
                  onChange={(e) => {
                    updateFieldDefault(
                      field.name + OFFSET,
                      e.target.value,
                      NumberFieldType.EUR,
                    )
                  }}
                />
              </span>
            </div>
          )
        })}
      </div>
      <div className='settings-form'>
        <div className='settings-header'>
          <h4>Custom triggers</h4>
        </div>
        {timingStore.getTimings
          .filter((timing) => timing.userDefined)
          .map((timing) => {
            return (
              <TimingEntry
                key={timing.trigger}
                timing={timing}
                removeTiming={async () => {
                  await removeTiming(timing.trigger)
                }}
              />
            )
          })}
        {!timingFormVisible ? (
          <button
            onClick={() => {
              setTimingFormVisible(true)
            }}
          >
            Add trigger
          </button>
        ) : (
          <TimingForm
            closeForm={() => {
              setTimingFormVisible(false)
            }}
            addTiming={addTiming}
          />
        )}
      </div>
    </div>
  )
})

export default Settings
