import { observer } from 'mobx-react'
import './OrderBookSelector.scss'
import { type Exchange, id, type Portfolio } from '../../classes/Portfolio'
import { type Product } from '../../classes/Product'
import { useEffect, useState } from 'react'
import { formatPowerHour } from '../../Util'
import { MAX_NUMBER_OF_TABLES } from '../../pages/OrderBook/OrderBookPage'
import { getContracts } from '../../actions/Portfolios'
import { useStores } from '../../use-stores'


function formatPortfolioName(name: string): string {
  return name.replace('ID-', '').replace('-BDEnergy', '')
}

export function formatName(portfolio: Portfolio, product: Product) {
  return `${formatPortfolioName(portfolio.name)} - ${formatPowerHour(product)}`
}

/**
 * @param portfolios must have at least one element
 */
const OrderBookSelector = observer(
  ({
     portfolios,
     cancel,
     insert,
   }: {
    portfolios: Portfolio[]
    cancel: () => void
    insert: (
      portfolio: Portfolio,
      contractId: string,
      name: string,
    ) => void
  }) => {
    const { configStore } = useStores()
    const { exchanges } = configStore
    const [exchange, setExchange] = useState(exchanges[0])
    const [contracts, setContracts] = useState<Product[]>([])
    const [portfolio, setPortfolio] = useState(portfolios.filter(p => p.exchange === exchange)[0])
    const [contract, setContract] = useState<string | undefined>(undefined)

    function handleAreaSelection(e: React.ChangeEvent<HTMLSelectElement>) {
      const portfolioId = e.target.value
      setPortfolio(portfolios.find(portfolio => id(portfolio) === portfolioId) as Portfolio)
    }

    function handleContractSelection(e: React.ChangeEvent<HTMLSelectElement>) {
      setContract(e.target.value)
    }

    function insertHandler() {
      if (portfolio === undefined) {
        throw new Error('Area not found, this should not happen')
      }
      const contract_ = contracts.find((c) => c.contractId === contract)
      if (contract_ === undefined) {
        throw new Error('Contract not found, this should not happen')
      }

      insert(
        portfolio,
        contract as string,
        formatName(portfolio, contract_),
      )
    }

    function fillHandler() {
      if (portfolio === undefined) {
        throw new Error('Area not found, this should not happen')
      }

      const contractIdx = contracts.findIndex((c) => c.contractId === contract)
      if (contractIdx === -1) {
        throw new Error('Contract not found, this should not happen')
      }

      const endIdx = Math.min(
        contractIdx + MAX_NUMBER_OF_TABLES,
        contracts.length,
      )

      for (let i = contractIdx; i < endIdx; i++) {
        insert(
          portfolio,
          contracts[i].contractId,
          formatName(portfolio, contracts[i]),
        )
      }
    }

    useEffect(() => {
      // if (area === undefined) return
      if (portfolio === undefined) return
      setContracts([])
      setContract(undefined)

      getContracts(portfolio).then((contracts) => {
        if (contracts.length === 0) return
        contracts.sort((a, b) => a.name.localeCompare(b.name))
        setContracts(contracts)
        setContract(contracts[0].contractId)
      })
    }, [portfolio])

    useEffect(() => {
      setPortfolio(portfolios.filter(p => p.exchange === exchange)[0])
    }, [exchange])

    const exchangeSelector = exchanges.length > 1 ? <select onChange={
      (e) => {
        setExchange(e.target.value as Exchange)
      }
    }>
      {exchanges.map((exchange) => (
        <option key={exchange} value={exchange}>{exchange.replace('_', ' ')}</option>
      ))}
    </select> : <div>{exchange.replace('_', ' ')}</div>

    return (
      <div className='order-book-selector'>
        <div className='selector'>
          <label>Exchange: </label>
          {exchangeSelector}
        </div>
        <div className='selector'>
          <label>Area: </label>
          <select onChange={handleAreaSelection}>
            {portfolios
              .filter(p => p.exchange === exchange)
              .map((portfolio) => (
                <option key={id(portfolio)} value={id(portfolio)}>
                  {formatPortfolioName(portfolio.name)}
                </option>
              ))
            }
          </select>
        </div>
        <div className='selector'>
          <label>Contract: </label>
          <select onChange={handleContractSelection}>
            {contracts.length === 0 ? (
              <option>Loading...</option>
            ) : (
              contracts.map((contract) => (
                <option key={contract.contractId} value={contract.contractId}>
                  {formatPowerHour(contract)}
                </option>
              ))
            )}
          </select>
        </div>
        <br />
        <div>
          <button onClick={insertHandler} disabled={!contract}>
            Insert
          </button>
          <button onClick={fillHandler} disabled={!contract}>
            Fill
          </button>
          <button onClick={cancel}>Cancel</button>
        </div>
      </div>
    )
  },
)

export default OrderBookSelector
