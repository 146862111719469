import React, { type FC, Fragment, useEffect, useState } from 'react'
import './algorithm-menu.scss'
import { observer } from 'mobx-react'
import axiosInstance from '../../axiosInstance'
import { toast } from 'react-toastify'
import {
  type AlgorithmField,
  type AlgorithmState,
  fields2Dictionary,
  FieldType,
  type AlgorithmSelectField,
} from '../../classes/AlgorithmState'
import { IoMdSettings } from 'react-icons/io'
import { useStores } from '../../use-stores'
import { getMultiplier } from '../../Util'
import { type IMessage } from '@stomp/stompjs'
import { type NumberFieldType } from '../../classes/StrategySchema'
import MultiProductCounter from './MultiProduct/MultiProductCounter'
import { MultiProduct, multiProductEquals } from './MultiProduct/MultiProduct'
import { id } from '../../classes/Portfolio'

const AlgorithmMenu = observer(() => {
  const { socketStore } = useStores()
  const { socket, connected } = socketStore

  const [algorithms, setAlgorithms] = useState<AlgorithmState[]>([])
  const [open, setOpen] = useState<string[]>([])

  const toggleOpen = (name: string) => {
    if (open.includes(name)) setOpen((opened) => opened.filter((n) => n !== name))
    else setOpen((opened) => [...opened, name])
  }

  const fetchAlgorithms = () => {
    axiosInstance
      .get<AlgorithmState[]>('api/algorithms')
      .then((res) => {
        setAlgorithms(res.data)
      })
      .catch(() => toast.error('Could not load algorithms'))
  }

  const algorithmStateUpdated = (message: IMessage) => {
    const state: AlgorithmState = JSON.parse(message.body)
    setAlgorithms((algorithms) => [...algorithms.filter((alg) => alg.name !== state.name), state])
  }

  useEffect(() => {
    if (socket.connected) {
      socket.subscribe('/topic/algorithms', algorithmStateUpdated)
    }
  }, [socket, connected])

  useEffect(() => {
    fetchAlgorithms()
  }, [])

  const toggle = (name: string) => {
    axiosInstance
      .put(`api/algorithms/toggle/${name}`)
      .then(() => {
        fetchAlgorithms()
      })
      .catch((err) => {
        const error = err.response.data.message // If it is a custom error from backend, find this message
        if (error) toast.error(`Could not toggle ${name}:\n` + error)
        else toast.error(`Could not toggle ${name}`)
      })
  }

  return (
    <div className='algorithm-menu'>
      <table>
        <thead>
        <tr>
          <td>Algorithms</td>
        </tr>
        <tr>
          <td>Name</td>
          <td>Status</td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        {algorithms
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((algorithm) => (
            <Fragment key={algorithm.name}>
              <tr key={algorithm.name}>
                <td>{algorithm.name}</td>
                <td>{algorithm.active ? 'Running' : 'Inactive'}</td>
                <td>
                  <button
                    className={algorithm.active ? 'on' : 'off'}
                    onClick={() => {
                      toggle(algorithm.name)
                    }}
                  >
                    {`Turn ${algorithm.active ? 'off' : 'on'}`}
                  </button>
                </td>
                {algorithm.fields.length > 0 && (
                  <td
                    style={{ cursor: 'pointer', width: '18px' }}
                    onClick={() => {
                      toggleOpen(algorithm.name)
                    }}
                  >
                    <IoMdSettings />
                  </td>
                )}
              </tr>
              {open.includes(algorithm.name) && (
                <ConfigureAlgorithmMenu key={`menu-${algorithm.name}`} algorithm={algorithm} />
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
})

interface ConfigureProps {
  algorithm: AlgorithmState
}

// Sort algorithm fields
const sortFields = (a: AlgorithmField, b: AlgorithmField) => {
  if (a.type > b.type) return 1
  if (a.type < b.type) return -1
  if (a.name > b.name) return 1
  return -1
}

// Menu for configuring an algorithm
const ConfigureAlgorithmMenu: FC<ConfigureProps> = observer(({ algorithm }) => {
  const { configStore } = useStores()
  const { portfolios, portfolio: portfolioIdx, pickedProducts } = configStore
  const portfolio = portfolioIdx !== undefined ? portfolios[portfolioIdx] : undefined
  const [state, setState] = useState(fields2Dictionary(algorithm.fields))

  const hasChanged = algorithm.fields.some((field) => field.value !== state[field.name])

  const onChange = (name: string, value: any, fieldType: FieldType) => {
    const dictionary = Object.assign({}, state)
    switch (fieldType) {
      case FieldType.AreaOrNone:
      case FieldType.Area:
      case FieldType.Select:
      case FieldType.Tags:
      case FieldType.MultiProduct:
      case FieldType.Int:
        dictionary[name] = value
        break
      case FieldType.EUR:
        dictionary[name] = value * 100
        break
      case FieldType.MW:
        dictionary[name] = value * 1000
    }
    setState(dictionary)
  }

  const save = () => {
    // Post strategy invocation
    if (algorithm.external)
      axiosInstance
        .post('api/algorithms/update-external', {
          name: algorithm.name,
          active: algorithm.active,
          fields: state,
        })
        .catch((err) => {
          const error = err.response.data.message // If it is a custom error from backend, find this message
          if (error) toast.error(error)
          else toast.error('Could not save changes')
        })
    else axiosInstance.post('api/algorithms', state).catch(() => toast.error('Could not save changes'))
  }

  const fieldHasChanged = (name: string, value: any) => state[name] !== value
  const multiProductFieldHasChanged = (name: string, value: MultiProduct) => !multiProductEquals(state[name], value)

  return (
    <tr>
      <td className='algorithm-menu-config' colSpan={4}>
        <div>
          {algorithm.fields.sort(sortFields).map((field) => {
            if (field.type === FieldType.Area || field.type === FieldType.AreaOrNone) {
              return (
                <div className='field-group' key={field.name}>
                  <p className={fieldHasChanged(field.name, field.value) ? 'changed' : ''}>
                    {field.nameUI}
                  </p>
                  <select
                    value={state[field.name]}
                    onChange={(e) => {
                      onChange(field.name, e.target.value, field.type)
                    }}
                  >
                    {portfolios.filter(p => p.exchange === 'NORD_POOL').map((portfolio) => (
                      <option key={id(portfolio)} value={portfolio.eic}>
                        {portfolio.name}
                      </option>
                    ))}

                    {field.type === FieldType.AreaOrNone && (
                      <option key='None' value={-1}>
                        None
                      </option>
                    )}
                  </select>
                </div>
              )
            }
            if (field.type === FieldType.Select) {
              return (
                <div className='field-group' key={field.name}>
                  <p className={fieldHasChanged(field.name, field.value) ? 'changed' : ''}>
                    {field.nameUI}
                  </p>

                  <select
                    value={state[field.name]}
                    onChange={(e) => {
                      onChange(field.name, e.target.value, field.type)
                    }}
                  >
                    {(field as AlgorithmSelectField).values.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              )
            }
            if (field.type === FieldType.Tags) {
              return (
                <div className='field-group' key={field.name}>
                  <p className={fieldHasChanged(field.name, field.value) ? 'changed' : ''}>
                    {field.nameUI}
                  </p>
                  <input
                    type='text'
                    value={state[field.name]}
                    onChange={(e) => {
                      onChange(field.name, e.target.value, field.type)
                    }}
                  />
                </div>
              )
            }
            if (field.type === FieldType.MultiProduct) {
              return (
                <div className='field-group' key={field.name}>
                  <p className={multiProductFieldHasChanged(field.name, field.value) ? 'changed' : ''}>
                    {field.nameUI}
                  </p>
                  <div className='multi-product-select-group'>
                    <MultiProductCounter multiProduct={state[field.name]} />
                    <button
                      onClick={(e) => {
                        if (portfolio === undefined) return

                        const contractIds = pickedProducts.map((product) => product.contractId)
                        const multiProduct = new MultiProduct(contractIds, contractIds.length !== 0 ? portfolio.eic : null)

                        onChange(field.name, multiProduct, field.type)
                      }}
                    >
                      Select
                    </button>
                  </div>
                </div>
              )
            } else {
              return (
                <div className='field-group' key={field.name}>
                  <p className={fieldHasChanged(field.name, field.value) ? 'changed' : ''}>
                    {field.nameUI}
                  </p>
                  <span className={field.type.toLowerCase()}>
                  <input
                    type='number'
                    value={state[field.name] / getMultiplier(field.type.valueOf() as NumberFieldType)}
                    onChange={(e) => {
                      onChange(field.name, Number(e.target.value), field.type)
                    }}
                  />
                </span>
                </div>
              )
            }
          })}
          <button disabled={!hasChanged} onClick={save}>
          Save
          </button>
        </div>
      </td>
    </tr>
  )
})

export default AlgorithmMenu
