import { type Product } from './classes/Product'
import { NumberFieldType } from './classes/StrategySchema'
import { toast } from 'react-toastify'
import type { Portfolio } from './classes/Portfolio'

export const getMultiplier = (type: NumberFieldType) => {
  if (type === NumberFieldType.EUR) return 100
  else if (type === NumberFieldType.MW) return 1000
  else return 1
}

/** String value used for differentiating settings for offset and regular defaults */
export const OFFSET = 'Offset'

// Get short portfolio name
export const shortPortfolioName = (p: Portfolio) =>
  p.exchange === 'NORD_POOL' ? p.name.split('-')[1] : p.name

export function formatPowerHour(product: Product): string {
  const split = product.name.split('-')
  // Split nord pool, epex just return string
  if (split.length > 2) return `${split[0]}-${split[2]}`
  else return product.name
}

export function formatPowerHourName(name: string): string {
  const split = name.split('-')

  if (split.length > 2) return `${split[0]}-${split[2]}`
  else return name
}

// Multiplier/divider for long values.
export enum Multiplier {
  EUR = 100,
  MW = 1000
}

// Copy a string to clipboard
export const copyToClipboard = (s: string) => {
  navigator.clipboard.writeText(s)
    .then(() => toast.success('Copied to clipboard', { autoClose: 2000 } ))
}

// Returns itself
export const identity = <T> (n: T) => n

// Takes a list of elements and a grouping function. Returns map of grouped elements
export const groupBy = <T, U>(elements: T[], grouping: (a: T) => U) => {
  const grouped = new Map<U, T[]>()
  for (const value of elements) {
    const key = grouping(value)
    const lst = grouped.get(key) ?? []
    lst.push(value)
    grouped.set(key, lst)
  }
  return grouped
}

