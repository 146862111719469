import { type BaseAuction, isAuction } from '../../../classes/Auction'
import { type FC } from 'react'
import { lowerCaseAllButFirst } from '../../../components/Executor/Executor'
import { BaseAuctionConfig } from './BaseAuctionConfig'

interface HeaderProps {
  auction: BaseAuction
}

export const AuctionHeader: FC<HeaderProps> = ({ auction }) => {
  return (
    <div className="auction-header">
      <div className="left">
        <div className="auction-title">
          <div className="auction-name">{auction.name}</div>
          {isAuction(auction) && <div className="auction-area">{auction.portfolioName}</div>}
          {isAuction(auction) && <div className="auction-area">{auction.area.name}</div>}
          <div className="auction-area">{new Date(auction.deliveryDate).toLocaleDateString('en-US')}</div>
        </div>
        <div className="auction-status">{'Status: ' + lowerCaseAllButFirst(auction.status).replace('_', ' ')}</div>
      </div>

      <div className="right">
        <BaseAuctionConfig auction={auction} />
      </div>
    </div>
  )
}
