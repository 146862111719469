import { observer } from 'mobx-react'
import { useStores } from '../../use-stores'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import axiosInstance from '../../axiosInstance'
import { toast } from 'react-toastify'
import { type Dictionary } from '../../classes/Order'
import './capacity-overview.scss'
import { type Product } from '../../classes/Product'
import { type IMessage, type StompSubscription } from '@stomp/stompjs'
import { type Area, type Capacity } from '../../classes/Capacity'
import { Cell, HCell, Row } from '../../components/Table/Table'
import { Cross } from '../../components/Icons/Icons'
import { getContracts, getPortfolios } from '../../actions/Portfolios'
import { type Portfolio } from '../../classes/Portfolio'

// 400 MW
const CAP_LIMIT = 400000

/** Function for filtering capacities. Set 'not' to true if we want filterNot */
const equal = (param: Capacity, not = false) =>
  (test: Capacity) => {
    const bool =
      param.deliveryAreaTo === test.deliveryAreaTo &&
      param.deliveryAreaFrom === test.deliveryAreaFrom &&
      param.deliveryStart === test.deliveryStart

    return not ? !bool : bool
  }

const formatNumber = (number: number) =>
  number === 0
    ? '0'
    : number.toLocaleString('dk-DA', {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      })

const productToShortName = (product: Product) => {
  const split = product.name.split('-')
  return `${split[0]}-${split[2]}`
}

// Get coloring (via className) from an event
const eventColor = (
  event:
    | 'coupling'
    | 'decoupling'
    | 'coupling-still'
    | 'decoupling-still'
    | undefined
) => {
  if (event === 'coupling') return 'green'
  if (event === 'decoupling') return 'red'
  if (event === 'coupling-still') return 'green-still'
  if (event === 'decoupling-still') return 'red-still'
  return ''
}

// Check old capacity and new capacity to see if we need to attach an event to the capacity
const hasEvent = (oldCap: Capacity, newCap: Capacity) => {
  // If we go from 0 to something higher, we say we are coupling
  if (oldCap.inCapacity === 0 && newCap.inCapacity > 0) return 'coupling'
  if (oldCap.outCapacity === 0 && newCap.outCapacity > 0) return 'coupling'

  // If we go from at least 400MW to something below that, we say there is a chance of decoupling
  if (oldCap.inCapacity >= CAP_LIMIT && newCap.inCapacity < CAP_LIMIT) {
    return 'decoupling'
  }
  if (oldCap.outCapacity >= CAP_LIMIT && newCap.outCapacity < CAP_LIMIT) {
    return 'decoupling'
  }

  // If we were already decoupling before, keep that state if it is still true
  if (oldCap.event === 'decoupling' || oldCap.event === 'decoupling-still') {
    if (newCap.inCapacity < CAP_LIMIT || newCap.outCapacity < CAP_LIMIT) {
      return 'decoupling-still'
    }
  }

  // If we were coupling before and we are still under CAP_LIMIT, use coupling-still
  if (oldCap.event === 'coupling' || oldCap.event === 'coupling-still') {
    if (newCap.inCapacity < CAP_LIMIT && newCap.inCapacity > 0)
      return 'coupling-still'
    if (newCap.outCapacity < CAP_LIMIT && newCap.outCapacity > 0)
      return 'coupling-still'
  }

  // Else no event
  return undefined
}

// Check if area has an event in its capacities. Filter by delivery start, so we don't show events for closed hours.
// Takes first event, cannot do anything about events going both ways when signaling via colors
const areaHasEvent = (
  capacities: Capacity[],
  area: string,
  deliveryStart: string
) =>
  eventColor(
    capacities
      .filter((capacity) => capacity.deliveryStart >= deliveryStart)
      .find((capacity) => capacity.deliveryAreaTo === area && capacity.event)
      ?.event
  )

// Get next delivery start. Take time now, add 2 hours, and go to the start of that hour
const getNextDeliveryStart = () => {
  const date = new Date()
  date.setHours(date.getHours() + 2)
  date.setMinutes(0, 0, 0)
  return date.toISOString()
}

const CAP = 'CAP-'
const EXCLUDED = '-EXCLUDED'
const SORTING = '-SORTING'

const useLocalStorage: <T>(key: string, defaultValue: T) => [T, React.Dispatch<React.SetStateAction<T>>] = <T,>(key: string, defaultValue: T) => {
  const storageItem = localStorage.getItem(key)
  const item: T = storageItem ? JSON.parse(storageItem) : defaultValue
  const [value, setValue] = useState<T>(item)

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [value])

  return [ value, setValue ]
}

const CapacityOverview = observer((props: { height: string, eic: string, remove: (a: string) => void }) => {
  const { socketStore, connectionStore } = useStores()
  const { connected } = socketStore
  const { backendConnectionChanged } = connectionStore

  const { height, eic, remove } = props

  // Areas that are not selected + the sorting order of items
  const [excluded, setExcluded] = useLocalStorage<Area[]>(CAP + eic + EXCLUDED, [])
  const [sorting, setSorting] = useLocalStorage<string[]>(CAP + eic + SORTING, [])

  const [areas, setAreas] = useState<Area[]>([])
  const [capacities, setCapacities] = useState<Capacity[]>([])
  const [portfolio, setPortfolio] = useState<Portfolio | undefined>()
  const [products, setProducts] = useState<Product[]>()

  const selectedAreas = areas.filter(a => excluded.findIndex(e => e.code === a.code ) === -1)

  const [subscription, setSubscription] = useState<StompSubscription | undefined>(undefined)

  /** Update capacities with new value */
  const updateCapacity = (capacity: Capacity) => {
    setCapacities((caps) => {
      // If we have one for from, to and delivery start combination
      const current = caps.find(equal(capacity))

      // If combination exists and has higher or equal seq number, do not replace
      if (current != null && current.eventSequenceNo >= capacity.eventSequenceNo) {
        return caps
      }

      if (current === undefined) {
        return [...caps, capacity]
      } else {
        const filtered = caps.filter(equal(capacity, true))
        return [
          ...filtered,
          Object.assign({}, capacity, { event: hasEvent(current, capacity) }),
        ]
      }
    })
  }

  useEffect(() => {
    axiosInstance
      .get<Area[]>(`api/capacities/${eic}/areas`)
      .then((res) => {
        setAreas(res.data)
        // If sorting is empty, add sorting by area code (first time)
        if (sorting?.length < 1) {
          const sorted = res.data.sort((a, b) => (a.code > b.code ? 1 : -1))
          setSorting(sorted.map(a => a.code))
        }
      })
      .catch(() => toast.error(`Could not get capacity areas for ${portfolio?.name}`))

    axiosInstance
      .get<Capacity[]>(`api/capacities/${eic}`)
      .then((res) => {
        res.data.forEach((cap) => {
          updateCapacity(cap)
        })
      })
      .catch(() => toast.error(`Could not get capacities for ${portfolio?.name}`))

    getPortfolios()
      .then(portfolios => {
        setPortfolio(portfolios.find((p) => p.eic === eic))
      })
      .catch(() => toast.error('Could not get area name'))
  }, [backendConnectionChanged])

  // Get contract names for contract column
  useEffect(() => {
    if (portfolio === undefined) return

    getContracts(portfolio)
      .then(products => { setProducts(products) })
      .catch(() => toast.error('Could not get contracts'))
  }, [portfolio])

  useEffect(() => {
    if (connected && subscription == null) {
      const destination = `/topic/capacities/${eic}`
      const sub = socketStore.socket.subscribe(
        destination,
        (message: IMessage) => {
          updateCapacity(JSON.parse(message.body))
        }
      )
      setSubscription(sub)
    }

    return () => {
      if (subscription != null) subscription.unsubscribe()
    }
  }, [connected])

  const removeArea = (area: Area) => {
    setExcluded(existing => [area, ...existing]);
  }

  // Create map of capacities
  const capacityMap: Dictionary<Capacity[]> = {}
  const deliveryStart = getNextDeliveryStart()

  // Fill map, deliveryStart -> capacities
  capacities
    .filter((capacity) => capacity.deliveryStart >= deliveryStart)
    .forEach((capacity) => {
      if (capacityMap[capacity.deliveryStart] === undefined) {
        capacityMap[capacity.deliveryStart] = [capacity]
      } else capacityMap[capacity.deliveryStart].push(capacity)
    })

  const selectableAreas = areas
    .filter((area) => excluded.findIndex((a) => a.code === area.code) > -1)
    .sort((a, b) => sorting?.indexOf(a.code) - sorting?.indexOf(b.code))
    .map((area) => (
      <div
        key={area.name}
        className={areaHasEvent(capacities, area.code, deliveryStart)}
        onClick={() => {
          setExcluded(existing => existing.filter(a => a.code !== area.code))
        }}
      >
        {area.name}
      </div>
    ))

  // Hold deliveryStart of marked row
  const [marked, setMarked] = useState<string | undefined>(undefined)

  // Create list of rows sorted by
  const rows = Object.keys(capacityMap)
    .sort()
    .map((deliveryStart) => {
      const product = products?.find((p) => p.deliveryStart === deliveryStart)
      const productName =
        product !== undefined ? productToShortName(product) : ''

      return (
        <Row
            key={deliveryStart}
            onClick={() => {
              // If we click a new row, set that as marked, else we are clicking the same and we unmark it
              setMarked(old => old === deliveryStart ? undefined : deliveryStart) }
            }
            highlighted={marked === deliveryStart}
        >
          {[
            <Cell key={deliveryStart} className="contract-column right">
              {productName}
            </Cell>,
            ...capacityMap[deliveryStart]
              .filter(
                (capacity) =>
                  selectedAreas.findIndex(
                    (area) => area.code === capacity.deliveryAreaTo
                  ) > -1
              )
              .sort((a, b) => sorting.indexOf(a.deliveryAreaTo) - sorting.indexOf(b.deliveryAreaTo))
              .map((capacity) => (
                <Fragment key={capacity.deliveryAreaTo}>
                  <Cell
                    key={capacity.deliveryAreaTo + 'in'}
                    className={`left ${eventColor(capacity.event)}`}
                  >
                    {formatNumber(capacity.outCapacity / 1000)}
                  </Cell>
                  <Cell
                    key={capacity.deliveryAreaTo + 'out'}
                    className={`right ${eventColor(capacity.event)}`}
                  >
                    {formatNumber(capacity.inCapacity / 1000)}
                  </Cell>
                </Fragment>
              )),
          ]}
        </Row>
      )
    })

  // Contract column ref. Used for finding width that table is offset with
  const ccRef = useRef<any>()
  // Body ref. This has the full size of the table
  const bodyRef = useRef<any>()
  // Scroll over body tells us how much of screen is missing on left side
  const scrollRef = useRef<any>()
  // Index of area we are moving
  const [movingIndex, setMovingIndex] = useState<number | undefined>(undefined)

  const getWidthAndOffset = () => {
    const leftOffset = ccRef.current?.offsetWidth
    const fullWidth = bodyRef.current?.offsetWidth
    const fullColumnWidth = fullWidth - leftOffset
    const elemWidth = fullColumnWidth / selectedAreas.length
    return { elemWidth, leftOffset }
  }

  // Get index of column where we start drag
  const onDragStart = (e: React.DragEvent) => {
    const { elemWidth, leftOffset } = getWidthAndOffset()
    const { scrollLeft } = scrollRef.current

    const index = Math.floor(((e.clientX + scrollLeft) - leftOffset) / elemWidth)
    setMovingIndex(() => index)
  }

  // Get index of column where we end drag. Move movingIndex to the index of underlying area in the sorting array
  const onDragEnd = (e: React.DragEvent) => {
    const { elemWidth, leftOffset } = getWidthAndOffset()
    const { scrollLeft } = scrollRef.current

    // This is the index we are moving the value to
    const index = Math.floor(((e.clientX + scrollLeft) - leftOffset) / elemWidth)

    // Move movingIndex to the index of the area we are hovering above
    if (movingIndex !== undefined) {
      // Get the actual areas
      const area1 = selectedAreas[movingIndex]
      const area2 = selectedAreas[index]

      // Get index of area2 in the sorting array
      // Filter area1 one and add it at the index
      const area2Index = sorting.indexOf(area2.code)
      const filtered = sorting.filter(i => i !== area1.code )
      filtered.splice(area2Index, 0, area1.code)
      setSorting(filtered)
    }

    setMovingIndex(undefined)
  }

  return (
    <div className="capacity-overview" style={{ height: props.height }}>
      {/* We need to add toast container as this opens outside of the regular SPA, and hence cannot access the container from there */}
      <div className="capacity-menu">
        <div style={{ display: 'flex', gap: '5px'}}>
          {`Capacities ${portfolio ? `(${portfolio?.name?.split('-')[1]})` : ''}`}
          <div onClick={() => { remove(eic) }}>
            <Cross />
          </div>
        </div>
        <div className="area-selector">
          {/* Add a span of ',' between the areas */}
          {selectableAreas.map((area, idx) => {
            if (idx < selectableAreas.length - 1) {
              return (
                <Fragment key={area.key}>
                  {area}
                  <span>{', '}</span>
                </Fragment>
              )
            } else return area
          })}
        </div>
      </div>
      <div>
        <div className="table" ref={scrollRef}>
          <div className="header">
            <Row>
              {[
                <Cell key={'cc'} className="contract-column" reference={ccRef}/>,
                ...selectedAreas
                  .sort((a, b) => (sorting.indexOf(a.code) - sorting.indexOf(b.code)))
                  .map((area) => (
                    <HCell
                      key={area.name}
                      onClick={() => { removeArea(area) }}
                      onDragStart={onDragStart}
                      onDragEnd={onDragEnd}
                    >
                      {area.name}
                    </HCell>
                  )),
              ]}
            </Row>
          </div>
          {/** Outer body to scroll */}
          <div
            className='outer-body'
            style={{ height: `calc(${height} - 4px)`}}
          >
            <div className="body" style={{ height: `calc(${rows.length} * var(--cell-height)`}} ref={bodyRef}>{rows}</div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default CapacityOverview
