import {
  type AllocationAuction,
  type Auction,
  type BaseAuction,
  isAllocation,
  isAuction,
} from '../../../classes/Auction'
import React, { type FC, useEffect, useRef, useState } from 'react'
import moment from 'moment/moment'
import DatePicker from '../../../components/DatePicker/DatePicker'
import { getAllocationAuctions, getAuctions } from '../../../actions/Auctions'
import './auctions-bar.scss'
import { toast } from 'react-toastify'
import { SideBarElement } from './SideBarElement'
import { SideBarTree } from './SideBarTree'

const isDevMode = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

interface AuctionsBarProps {
  auction?: BaseAuction
  setAuction: React.Dispatch<React.SetStateAction<BaseAuction | undefined>>
}

// Sort order based on auction name and area name
const auctionSort = (a1: Auction, a2: Auction) => {
  if (a1.uiName() === a2.uiName()) {
    return a1.portfolioName.localeCompare(a2.portfolioName)
  }

  return a1.uiName().localeCompare(a2.uiName())
}

// Component with a date picker for choosing auctions.
const AuctionsBar: FC<AuctionsBarProps> = ({ auction, setAuction }) => {
  const timerIdRef = useRef<NodeJS.Timer>()

  const [date, setDate] = useState(moment().add(1, 'days'))
  const [auctions, setAuctions] = useState<Auction[]>([])
  const [allocations, setAllocations] = useState<AllocationAuction[]>([])

  // When we fetch updated auctions, we want to make sure we set the new version of the already picked auction
  const setAuctionIfPicked = (a: BaseAuction) => {
    if (auction?.key() === a.key()) setAuction(a)
  }

  const filter = (auctions: Auction[]): Auction[] => {
    if (isDevMode) return auctions
    return auctions.filter((auction) => auction.displayName != null)
  }

  useEffect(() => {
    const callback = () => {
      getAuctions(date).then((data) => {
        data.forEach((a) => {
          setAuctionIfPicked(a)
        })
        setAuctions(filter(data))
      })
      getAllocationAuctions(date).then((data) => {
        data.forEach((a) => {
          setAuctionIfPicked(a)
        })
        setAllocations(() => data)
      })
    }

    timerIdRef.current = setInterval(callback, 5000)

    return () => {
      clearInterval(timerIdRef.current)
    }
  }, [date, auction])

  useEffect(() => {
    // Set picked to undefined as we move to different day
    setAuction(undefined)
    // Get auctions and allocation auctions for day
    getAuctions(date)
      .then((data) => {
        setAuctions(filter(data))
      })
      .catch(() => toast.error('Could not get auction list'))
    getAllocationAuctions(date)
      .then((data) => {
        setAllocations(data)
      })
      .catch(() => toast.error('Could not get allocation list'))
  }, [date])

  return (
    <div className="auctions-sidebar">
      <div className="sticky bar-element">
        <div className="title">{'Delivery date'}</div>
        <DatePicker title={'Delivery date'} date={date} setDate={setDate} />
      </div>
      <div className="scrollable bar-element">
        <SideBarTree
          title={'Auctions'}
          auctions={auctions}
          auction={auction && isAuction(auction) ? auction : undefined}
          setAuction={setAuction}
          sorting={auctionSort}
        />
        <SideBarElement
          title={'Allocations'}
          auctions={allocations}
          auction={auction && isAllocation(auction) ? auction : undefined}
          setAuction={setAuction}
          sorting={(a, b) => a.name.localeCompare(b.name)}
        />
      </div>
    </div>
  )
}

export default AuctionsBar
