import { useEffect, useState } from 'react'

// Listen for updates to a local storage item. Uses defaultState only if there is no value in local storage
export const useLocalStorage = (key: string, defaultState?: string | (() => string)) => {
  const [state, setState] = useState<string | null>(localStorage.getItem(key) ?? defaultState ?? null)

  useEffect(() => {
    const handler = (event: StorageEvent) => {
      const { key, newValue } = event
      if (event.key !== key) return

      setState(newValue)
    }

    window.addEventListener('storage', handler)

    return () => {
      window.removeEventListener('storage', handler)
    }
  }, [])

  return state
}