import { makeAutoObservable } from 'mobx'

const THEME = 'THEME'
const HOURS_UI = 'HOURS_UI'
const SHOW_TRADES = 'SHOW_TRADES'
const SHOW_SPOT_PRICES = 'SHOW_SPOT_PRICES'
const SHOW_ALGORITHMS = 'SHOW_ALGORITHMS'
const USE_COLORED_AUCTION_CELLS = 'COLORED_AUCTION_CELLS'

export class UIStore {
  public theme = localStorage.getItem(THEME)
  public settingsVisible = false
  public hoursAhead = localStorage.getItem(HOURS_UI)
    ? Number(localStorage.getItem(HOURS_UI))
    : 12

  public showTrades = localStorage.getItem(SHOW_TRADES) === 'true'

  public showSpotPrices = localStorage.getItem(SHOW_SPOT_PRICES)
    ? Boolean(localStorage.getItem(SHOW_SPOT_PRICES))
    : false

  public showAlgorithms = localStorage.getItem(SHOW_ALGORITHMS)
    ? Boolean(localStorage.getItem(SHOW_ALGORITHMS))
    : false

  public useColoredAuctionCells = localStorage.getItem(USE_COLORED_AUCTION_CELLS) === 'true'

  constructor() {
    makeAutoObservable(this)
  }

  // For actually setting to boolean
  setSettingsVisibility = (visible: boolean) => {
    this.settingsVisible = visible
  }

  // For toggling, easier to use
  toggleSettings = () => {
    this.settingsVisible = !this.settingsVisible
  }

  setHoursAhead = (hours: number) => {
    if (hours < 12) return
    this.hoursAhead = hours
    localStorage.setItem(HOURS_UI, String(this.hoursAhead))
  }

  setTheme = (theme: string) => {
    this.theme = theme
    localStorage.setItem(THEME, theme)
  }

  setShowTrades = (show: boolean) => {
    this.showTrades = show
    localStorage.setItem(SHOW_TRADES, show.toString())
  }

  setShowSpotPrices = (show: boolean) => {
    this.showSpotPrices = show
    localStorage.setItem(SHOW_SPOT_PRICES, show.toString())
  }

  setShowAlgorithms = (show: boolean) => {
    this.showAlgorithms = show
    localStorage.setItem(SHOW_ALGORITHMS, show.toString())
  }

  setUseColoredAuctionCells = (show: boolean) => {
    this.useColoredAuctionCells = show
    localStorage.setItem(USE_COLORED_AUCTION_CELLS, show.toString())
  }
}
