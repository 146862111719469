import React, { type FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import type { Portfolio } from '../../../classes/Portfolio'
import type { Product } from '../../../classes/Product'
import { errorHandler } from '../errorHandler'
import { useAbortController } from '../../../hooks/useAbortController'
import moment, { type Moment } from 'moment'
import IntradayAuctionInfo from '../IntradayAuctionInfo/IntradayAuctionInfo'
import { calculateMidPointNomination } from '../calculateMidPointNomination'
import { type ContractGroup, SpreadRow } from '../SpreadRow/SpreadRow'
import type {
  ExchangeRate,
  HalfAuction,
  IDAuction,
  TransmissionRightAndMarginalPrice,
} from '../../../classes/Interconnector'
import './spread-auction.scss'
import type { SpreadTrade } from '../../../classes/SpreadTrade'
import { useSubscribe } from '../../../hooks/useSubscribe'
import useNotifications from '../../../hooks/useNotifications'
import { getExchangeRate } from '../../../actions/Interconnectors'
import { useContracts, usePortfolios } from '../hooks'

interface SpreadAuctionProps {
  halfAuction: HalfAuction
  auction?: IDAuction
  transmissionInfo: TransmissionRightAndMarginalPrice[]
  trades: SpreadTrade[]
}

// Check if exchange rates are necessary -> if they are, try finding one that fits the time, else return undefined
const findExchangeRateForDelivery = (exchangeRates: ExchangesRates, deliveryStart: Moment) => {

  // Takes a list of exchange rates and takes the one valid for [time]. Returns undefined if nothing valid can be found.
  const findExchangeRate = (rates: ExchangeRate[], time: Moment) => {
    const filtered = rates.filter(r => {
      return moment(r.startTime).isBefore(time) || moment(r.startTime).isSame(time)
    })

    if (filtered.length === 0) return undefined

    return filtered.reduce((prev, current) => {
      return (prev && prev.startTime > current.startTime) ? prev : current
    })
  }

  return exchangeRates === 'NotNecessary'
    ? 1
    : exchangeRates ? findExchangeRate(exchangeRates, deliveryStart)?.rate : undefined
}

/**
 * Get duration in minutes of product type
 * @param productId the name of the product type, e.g. XBID_Quarter_Hour_Power
 */
const getMinuteDuration = (productId: string): number => {
  const lowered = productId.toLowerCase()
  if (lowered.includes('quarter')) return 15
  if (lowered.includes('half')) return 30
  return 60
}

/**
 * Group contracts in delivery periods
 * @param deliveryStart the start time of the full delivery period
 * @param deliveryEnd the end time of the full delivery period
 * @param minutes the minute step-value between deliveryStart and deliveryEnd
 */
const groupContractsByDeliveryPeriod = (deliveryStart: Moment, deliveryEnd: Moment, minutes: number) => (importContracts: Product[], exportContracts: Product[]) => {
  const result: ContractGroup[] = []
  const start = deliveryStart.clone()

  // We modify start at the end of the loop
  // eslint-disable-next-line no-unmodified-loop-condition
  while (start < deliveryEnd) {
    const groupEnd = start.clone().add(minutes, 'minutes')

    result.push({
      deliveryStart: start.clone(),
      deliveryEnd: groupEnd,
      imports: importContracts.filter(c => start <= moment(c.deliveryStart) && moment(c.deliveryEnd) <= groupEnd),
      exports: exportContracts.filter(c => start <= moment(c.deliveryStart) && moment(c.deliveryEnd) <= groupEnd),
    })

    start.add(minutes, 'minutes')
  }

  return result
}

// Describes the three states of exchange rates.
// We either have them, they are not necessary because both sides are EUR or undefined because they are missing
type ExchangesRates = ExchangeRate[] | 'NotNecessary' | undefined

export const SpreadAuction: FC<SpreadAuctionProps> = observer(({ halfAuction, auction, transmissionInfo, trades }) => {
  const [importPortfolio, setImportPortfolio] = useState<Portfolio | undefined>()
  const [exportPortfolio, setExportPortfolio] = useState<Portfolio | undefined>()
  const [exchangeRates, setExchangeRates] = useState<ExchangesRates>(undefined)

  const portfolios = usePortfolios()
  const importContracts = useContracts(importPortfolio)
  const exportContracts = useContracts(exportPortfolio)

  // Handle notifications regarding our trades (if they are rejected mostly)
  const handleNotification = useNotifications()
  useSubscribe(`/topic/user/${halfAuction.tag}`, handleNotification)

  useEffect(() => {
    setImportPortfolio(() => portfolios.find(p => halfAuction.import === p.eic))
    setExportPortfolio(() => portfolios.find(p => halfAuction.export === p.eic))
  }, [portfolios, halfAuction])

  // Handle exchange rate for relevant cases (not EUR)!
  useAbortController(controller => {
    setExchangeRates(undefined)
    if (!auction) return
    if (!importPortfolio || !exportPortfolio) return

    // If they are both 'EUR' do not get anything
    if (importPortfolio.currency === 'EUR' && exportPortfolio.currency === 'EUR') {
      setExchangeRates('NotNecessary')
      return
    }

    const start = moment(auction.deliveryStart)
    const end = moment(auction.lastDeliveryStart)

    // If response is empty, set exchangeRates to undefined so the children cannot render
    getExchangeRate(start, end, 'GBP', controller.signal)
      .then(rates => setExchangeRates(rates.length > 0 ? rates : undefined))
      .catch(errorHandler('Could not get exchange rate'))
  }, [importPortfolio, exportPortfolio, auction, halfAuction])

  // We cannot proceed without these values
  if (!auction) return null
  if (!importPortfolio) return null
  if (!exportPortfolio) return null

  // If we have no exchange rate, but one of our portfolios is not 'EUR', we cannot load anything
  if (exchangeRates === undefined && (importPortfolio.currency !== 'EUR' || exportPortfolio.currency !== 'EUR')) {
    return null
  }

  const maxDurationInMinutes = Math.max(getMinuteDuration(importPortfolio.products[0]), getMinuteDuration(exportPortfolio.products[0]))
  const deliveryStart = moment(auction.deliveryStart)
  const deliveryEnd = moment(auction.lastDeliveryStart).add(maxDurationInMinutes, 'minutes')

  // Group the contracts into their respective delivery periods
  const groupContracts = groupContractsByDeliveryPeriod(deliveryStart, deliveryEnd, maxDurationInMinutes)
  const tradesForThisAuction = trades.filter(t => t.label.includes(halfAuction.tag))

  const midPointNomination = (contracts: { 'exports': Product[], 'imports': Product[] }) =>
    calculateMidPointNomination(importPortfolio, exportPortfolio, halfAuction, tradesForThisAuction, contracts)

  return <div className='intraday-auction'>
    <IntradayAuctionInfo
      start={deliveryStart}
      end={deliveryEnd}
      exportPortfolio={exportPortfolio}
      importPortfolio={importPortfolio}
    />
    <div className='table-container'>
      <div
        className='spread-table'
      >
        {groupContracts(importContracts, exportContracts)
          .sort((a, b) => a.deliveryStart.diff(b.deliveryStart))
          .map(group => {
            // Get the transmission right/marginal price for this delivery period, else nothing to render
            const info = transmissionInfo.find(({ transmissionRight}) =>
              moment(transmissionRight.startTime).isSame(group.deliveryStart)
            )
            if (!info) return null

            // Get exchange rates for this group
            const exchangeRate = findExchangeRateForDelivery(exchangeRates, group.deliveryStart)
            if (exchangeRate === undefined) return null

            const key = `${halfAuction.tag}${auction.type}${group.deliveryStart.toISOString()}`

            return (
              <SpreadRow
                key={key}
                transmissionRight={info.transmissionRight.transmissionRight}
                marginalPrice={info.marginalPrice.marginalPrice}
                currentMidInterconnectorNomination={midPointNomination({ 'exports': group.exports, 'imports': group.imports })}
                contractGroup={group}
                halfAuction={halfAuction}
                exportPortfolio={exportPortfolio} importPortfolio={importPortfolio}
                trades={tradesForThisAuction}
                gbpExchangeRate={exchangeRate}
              />
            )
          })}
      </div>
    </div>
  </div>
})