import { type Curve } from '../../classes/OrderPlan'
import { type CSSProperties, type FC, Fragment } from 'react'
import './curve-table.scss'
import { copyToClipboard, Multiplier } from '../../Util'
import { IoCopyOutline } from "react-icons/io5";

interface CurveTableProps {
  curves: Curve[]
  height: number
  style?: { useColoring?: boolean }
}

export interface Style extends CSSProperties {
  '--columns': number;
  '--rows': number
}

// If cell coloring is enabled, show green for positive, red for negative values
const getCellColor = (enabled?: boolean) => (value: number | null) => {
  if (enabled) {
    if (value !== null && value > 0) return 'green'
    if (value !== null && value < 0) return 'red'
  }
  return undefined
}

const CurveTable: FC<CurveTableProps> = ({ curves, height, style }) => {

  // Get all defined price steps
  const prices = new Set<number>()
  for (const curve of curves) {
    for (const point of curve.points) {
      prices.add(point.price)
    }
  }

  // Get all columns/rows in sorted order
  const columns = [...prices].sort((a, b) => a - b)
  const rows = curves.sort((a, b) => new Date(a.deliveryStart).getTime() - new Date(b.deliveryStart).getTime())

  // Create dictionary row -> (price -> volume)
  // Also create sum dictionary for every price step
  const tableDictionary: Record<string, Record<number, number>> = {}
  const sumDictionary: Record<number, number> = {}

  for (const row of rows) {
    // Create dictionary per row (per curve)
    const rowDictionary: Record<number, number> = {}

    // For each price step, assign the volume to the price step
    // Also add the volume to the sum for the price step
    for (const point of row.points) {
      const { price, volume } = point
      rowDictionary[price] = volume
      sumDictionary[price] = volume + (sumDictionary[price] ?? 0)
    }
    tableDictionary[row.contractName] = rowDictionary
  }

  const coloring = getCellColor(style?.useColoring)

  // Write table value to string for Excel
  const writeToString = () => {
    const headers = ['', ...columns.map(c => c / Multiplier.EUR)].join('\t')
    const rowLines = rows.map(row => {
      const cn  = row.contractName
      return [cn, ...columns.map(col => tableDictionary[cn][col] / Multiplier.MW ?? '')].join('\t')
    })
    return [headers, ...rowLines].join('\n')
  }

  return (
    <div
      style={{ '--columns': columns.length + 1, '--rows': rows.length + 2, height } as Style}
      className='grid-table'
    >
      { /** Add column headers */ }
      <>
        <div className='h l cell' id='corner-cell' onClick={() => { copyToClipboard(writeToString()) }} >
          <IoCopyOutline title={'Copy to clipboard'}/>
        </div>
        {columns.map(c => <div key={`col-${c}`} className='h cell'>{c / Multiplier.EUR}</div>)}
      </>
      { /** Add body */ }
      <>
        {rows.map(row => {
          const { contractName } = row

          // Return one row
          return (<Fragment key={contractName}>
            <div className='l cell'>{contractName}</div>
            {columns.map(col => {
              const value = tableDictionary[contractName][col] ?? null
              const cellValue = value !== null ? value / Multiplier.MW : null
              const color = coloring(cellValue)
              
              return <div
                key={`${contractName}-${col}`}
                className='cell'
                style={{ color }}
              >
                {cellValue}
              </div>
            })}
          </Fragment>)
        })}
      </>
      { /** Add sums */ }
      <>
        <div className='f l cell'>Sum</div>
        {columns.map(col => <div key={`sum-${col}`} className='f cell'>{(sumDictionary[col] ?? 0) / Multiplier.MW}</div>)}
      </>
    </div>
  )
}

export default CurveTable