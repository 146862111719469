import { type AllocationConfig } from './AllocationConfig'
import { type AuctionConfig } from './AuctionConfig'

export type AuctionStatus = 'NONE' | 'PENDING' | 'SUBMITTED' | 'UPDATE_AVAILABLE'
export type Exchange = 'NORDPOOL'
type Kind = 'Auction' | 'Allocation'

// Base auction
export interface BaseAuction {
  readonly type: Kind
  // Unique key for rendering a list
  key: () => string
  // Name to show in UI
  uiName: () => string
  // Type of auction
  status: AuctionStatus
  name: string
  deliveryDate: Date
}

export interface IAuction {
  auctionId: string
  exchange: Exchange
  name: string
  status: AuctionStatus
  area: AuctionArea
  portfolioId: string
  deliveryDate: Date
  biddingClose: Date
  portfolioName: string
  latestOrderPlanCreatedTime: string
  displayName?: string
  auctionConfig: AuctionConfig
}

export class Auction implements BaseAuction, IAuction {
  auctionId: string
  exchange: Exchange
  name: string
  status: AuctionStatus
  area: AuctionArea
  portfolioId: string
  deliveryDate: Date
  biddingClose: Date
  portfolioName: string
  latestOrderPlanCreatedTime: string
  displayName?: string
  auctionConfig: AuctionConfig

  constructor(json: IAuction) {
    this.auctionId = json.auctionId
    this.exchange = json.exchange
    this.name = json.name
    this.status = json.status
    this.area = json.area
    this.portfolioId = json.portfolioId
    this.deliveryDate = json.deliveryDate
    this.biddingClose = json.biddingClose
    this.portfolioName = json.portfolioName
    this.latestOrderPlanCreatedTime = json.latestOrderPlanCreatedTime
    this.displayName = json.displayName
    this.auctionConfig = json.auctionConfig
  }

  readonly type: Kind = 'Auction'
  key = () => `${this.auctionId}${this.portfolioId}`
  uiName = () => this.displayName ?? `${this.portfolioName} (${this.area.code})`
}
interface AuctionArea {
  eic: string
  code: string
  name: string
}

export interface IAllocationAuction {
  id: number
  deliveryDate: Date
  name: string
  status: AuctionStatus
  corridor1: string
  corridor2: string
  portfolio1: string
  portfolio2: string
  updateTime: string
  allocationConfig: AllocationConfig
}

export class AllocationAuction implements BaseAuction, IAllocationAuction {
  id: number
  name: string
  deliveryDate: Date
  status: AuctionStatus
  corridor1: string
  corridor2: string
  portfolio1: string
  portfolio2: string
  updateTime: string
  allocationConfig: AllocationConfig

  constructor(json: IAllocationAuction) {
    this.id = json.id
    this.name = json.name
    this.deliveryDate = json.deliveryDate
    this.status = json.status
    this.corridor1 = json.corridor1
    this.corridor2 = json.corridor2
    this.portfolio1 = json.portfolio1
    this.portfolio2 = json.portfolio2
    this.updateTime = json.updateTime
    this.allocationConfig = json.allocationConfig
  }

  readonly type: Kind = 'Allocation'
  key = () => this.name
  uiName = () => this.name
}

export const isAllocation = (base: BaseAuction): base is AllocationAuction => {
  return base.type === 'Allocation'
}

export const isAuction = (base: BaseAuction): base is Auction => {
  return base.type === 'Auction'
}
